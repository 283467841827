
import { computed, reactive, getCurrentInstance, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { getDataTypes, deleteDataType } from '@/api/settings/data-types';
import PackagedPagination from '@/components/pagination/Index.vue';
import { ElMessageBox } from 'element-plus';
import { getShowBool } from '@/utils/permission-show-module';
import { useSaList } from '@/shared/hooks/list';

interface TableState {
  multipleSelection: Array<object>;
}

export default {
  name: 'DataType',
  components: {
    PackagedPagination,
  },
  setup() {
    // 表单相关状态
    const tableState: TableState = reactive({
      multipleSelection: [],
    });
    const extendQuery = reactive({
      sortField: 'createTime',
      sortType: 'descending',
    });

    // 删除按钮是否可点击
    const isRemovable = computed(() => tableState.multipleSelection.length > 0);

    // 获取路由器信息
    const router = useRouter();

    // 获取组件实例
    const instance = getCurrentInstance();
    const { loading, list, total, query, fetchList, handleSearch, handlePageChange, handlePageSizeChange } = useSaList(
      async (query: any) => {
        loading.value = true;
        try {
          const {
            data: { rows, count },
          } = await getDataTypes({
            ...query,
            ...extendQuery,
          });
          list.value = rows;
          total.value = count;
        } catch (e) {
          console.log(e);
        }
        loading.value = false;
      },
    );

    fetchList();

    // 新增数据类型
    function addDataType() {
      router.push('datatype/addedit');
    }

    // 编辑数据类型
    const onEdit = (rowData: any) => {
      router.push(`datatype/edit/${rowData.id}`);
    };

    // 删除数据类型
    const onDelete = async (rowData: any) => {
      ElMessageBox.confirm(`是否删除选中?`, '提示', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const { code }: any = await deleteDataType({ ids: [rowData.id] });
        if (code === 0) {
          (instance as any).proxy.$message({
            type: 'success',
            message: '删除成功',
          });
          fetchList();
        } else {
          (instance as any).proxy.$message({
            type: 'error',
            message: '删除失败',
          });
        }
      });
    };

    // 批量删除数据类型
    const groupDelete = async () => {
      ElMessageBox.confirm(`是否删除选中?`, '提示', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        if (tableState.multipleSelection.every((item: any) => item.isSystem)) {
          (instance as any).proxy.$message({
            type: 'warn',
            message: '系统默认数据类型不可删除',
          });
          return;
        }
        const { code }: any = await deleteDataType({
          ids: [tableState.multipleSelection.map((item: any) => item.id)],
        });
        if (code === 0) {
          (instance as any).proxy.$message({
            type: 'success',
            message: '删除成功',
          });
          fetchList();
        } else {
          (instance as any).proxy.$message({
            type: 'error',
            message: '删除失败',
          });
        }
      });
    };
    // 数据类型勾选
    const handleSelectionChange = (val: Array<object>) => {
      tableState.multipleSelection = val;
    };

    // 表单排序
    const sortChange = ({ order, prop }: { order: 'ascending' | 'descending'; prop: string }) => {
      extendQuery.sortField = prop;
      extendQuery.sortType = order;
      fetchList();
    };

    return {
      ...toRefs(tableState),
      loading,
      list,
      total,
      query,
      fetchList,
      handleSearch,
      handlePageChange,
      handlePageSizeChange,
      isRemovable,
      addDataType,
      onEdit,
      onDelete,
      groupDelete,
      handleSelectionChange,
      sortChange,
      getShowBool,
    };
  },
};
